<template>
  <div class="views-home">
    <HomeHeader v-if="isShowHeader" @changeList="onHeaderChangeList" />
    <HomeNav />
    <!-- v-if="isLogin" -->
    <AiList v-if="is_active_ailist" />
    <!-- <SearchPlace /> -->
    <SideList />
    <UavDetail v-if="active == 10" />
    <CompanyDetail v-if="active == 11" />
    <!-- <RunProject v-if="active === 13" /> -->
    <!-- v-if="active === 12"
    <AchievementDatabase v-if="active === 12"/> -->
    <!--下载中心-->
    <DownloadCenter v-if="downoadPageShow" />
    <!-- 子平台 -->
    <ConTrolBar v-if="subplatFormStatus && ($eagleNestUav || $uav)" @changeAi="AiChange" />
    <!-- 任务创建-->
    <FlightTaskCreate v-if="active == 4" />
    <!-- 任务选择弹窗 -->
    <SelectTask v-if="active == 5" />
    <!-- 任务编辑 -->
    <FlightTaskEdit v-if="active == 6" />
    <!-- 任务操作弹窗 -->
    <FlightTaskTooptip v-if="active == 7" />
    <!--监控参数, v-show的作用是地图上显示标志 -->
    <!-- <monitoring-parameters
      v-show="leftMenuAction === 6 || (subplatFormStatus && subplatFormActive === 4)"
    /> -->
  </div>
</template>

<script>
import FlightPopup from '@/components/home/flight-popup';
// import AirwayPopup from '@/components/home/airway-edit/airway-popup'
import HomeNav from '@/components/home/navsilder';
import HomeHeader from '@/components/home/header/index';
import FlightTaskCreate from '@/components/home/flight-popup/flight-task-create';
import FlightTaskEdit from '@/components/home/flight-popup/flight-task-edit';
import FlightTaskTooptip from '@/components/home/flight-popup/flight-task-tooptip';
// import AirwayChange from '@/components/home/airway-edit/airway-change'
import ConTrolBar from '@/components/control-bar';
// import DashBoard from '@/components/observe/dashboard'
// import DashBoardZl from '@/components/observe1/dashboard'
import SelectTask from '@/components/home/select-task';
// import Player from '@/components/home/player'
// import PlayerAi from '@/components/home/player/ai.vue'
import AiList from '@/components/home/ailist';
// import SearchPlace from '@/components/home/search-place'
// import FlightLineTooptip from '@/components/home/flight-popup2/flight-line-tooptip'
import SideList from '@/components/home/sideList';
import UavDetail from '@/components/home/uav-detail';
import CompanyDetail from '@/components/home/company-detail';
// import AchievementDatabase from '@/components/home/achievement-database'
import DownloadCenter from '@/components/home/download-center';
import RunProject from '../uavApplications/components/run-project';
import MonitoringParameters from '@/components/home/monitoring-parameters/index.vue';
import uav from './data';
import methods from './methods';
import { mapState, mapMutations } from 'vuex';
import { Control_API, flightTaskAPI, LoginAPI } from '@/api';
export default {
  components: {
    HomeNav,
    HomeHeader,
    FlightPopup,
    // AirwayPopup,
    FlightTaskCreate,
    FlightTaskEdit,
    FlightTaskTooptip,
    // FlightLineTooptip,
    ConTrolBar,
    // DashBoard,
    SelectTask,
    // Player,
    // PlayerAi,
    AiList,
    // AirwayChange,
    // SearchPlace,
    // DashBoardZl,
    SideList,
    UavDetail,
    CompanyDetail,
    DownloadCenter,
    // AchievementDatabase,
    RunProject,
    MonitoringParameters
  },
  provide() {
    return {
      // 方法
      active: () => this.active,
      active_ailist: this.active_ailist,
      change_active: this.change_active,
      c_demo: this.c_demo,
      c_dashBoard: this.c_dashBoard,
      c_player: this.c_player,
      c_player_ai: this.c_player_ai,
      get_flight_info: this.get_flight_info,
      flight_info: () => this.flight_info,
      get_route_info: this.get_route_info,
      route_info: () => this.route_info,
      cancel_Handler: this.cancel_Handler,
      destroy_cesium: this.destroy_cesium,
      save_airline: this.save_airline,
      edit_point_info: this.edit_point_info,
      point_info: () => this.points_info,
      clear_airline: this.clear_airline,
      send_to_task_tooptip: this.send_to_task_tooptip,
      tooptip_flight_info: () => this.tooptip_flight_info,
      send_to_line_tooptip: this.send_to_line_tooptip,
      tooptip_line_info: () => this.tooptip_line_info,
      send_airline_info: this.send_airline_info,
      airline_info: () => this.airline,
      control_show: this.controlShow,
      get_flight_state: this.get_flight_state,
      get_sidelist_item: this.get_sidelist_item,
      side_list_item: () => this.side_list_item
    };
  },
  inject: ['g_cesium_layer', 'reload'],
  data() {
    return {
      isShowHeader: false,
      isLogin: localStorage.getItem('tmj_token'),
      is_active_ailist: false,
      ...uav,
      currentIndex: 0, // ai视频选项index
      active: 0,
      uavInfo: {},
      video_rtmp: {},
      see: false,
      videos: [],
      videoType: 'flv', // 优化使用的视频拉流类型
      list: {},
      deviceType: '', // 用于存储挂载类型
      controlShow: {
        isShow: 1
      }, // 控制台指令切换 1为指令 2为自适应
      c_demo: {
        isShow: false
      }, // 一键演示视频是否显示
      c_dashBoard: {
        isShow: false
      }, // 控制仪表盘是否显示
      c_player: {
        isShow: false
      }, // 控制视频播放器是否显示
      c_player_ai: {
        isShow: false
      }, // 控制视频播放器是否显示
      point_info: {},
      flight_info: {}, // 飞行任务
      trackPoints: {}, // 航迹
      aiInfo: {},
      route_info: {}, // 航线列表信息
      airline: {}, // 绘制的航线
      points_info: [], // 编辑后的航点
      tooptip_flight_info: {},
      tooptip_line_info: {},

      data_srouce_from_add: {}, // 规划的实体类
      positions_from_add: [],

      newest_pointsinfo: {},

      isFromEdit: false, // 是否来自编辑
      side_list_item: {},
      downoadPageShow: false // 下载中心页面
    };
  },
  computed: {
    ...mapState(['uavLoginInfo', 'userInfo', 'taskDeviceData', 'leftMenuAction']),
    ...mapState('subplatform', ['subplatFormStatus', 'subplatFormActive']),
    $eagleNestUav() {
      return this.$store.state.uavApplicationsEagleNest.currentEagle;
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    cesium_layer() {
      return this.g_cesium_layer();
    },
    video() {
      if (this.taskDeviceData) {
        const find = this.taskDeviceData?.data?.videoInfo?.find((item) => {
          return item.videoType === this.videoType;
        });

        if (find) {
          return {
            vUrl: find.vurl,
            videoType: find.videoType
          };
        } else {
          return {
            vUrl: this.taskDeviceData?.data?.videoInfo[0]?.vurl,
            videoType: this.taskDeviceData?.data?.videoInfo[0]?.videoType
          };
        }
      } else {
        return null;
      }
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    // 获取微信登录信息
    // this.wxLogin()
    if (localStorage.getItem('tmj_token') == null) {
      const href = window.location.href;
      const data1 = href.split('?')[1]?.split('#')[0]?.split('&');
      const data = {};
      data1.forEach((item, index) => {
        if (index == 0) {
          data.code = item.split('=')[1];
        }
        if (index == 1) {
          data.state = item.split('=')[1];
        }
      });
      if (data.code && data.state) {
        LoginAPI.wxToken(data)
          .then((res) => {
            window.localStorage.setItem('user_info', JSON.stringify(res.data));
            window.localStorage.setItem('tmj_token', res.data.token);
            this.SET_USERINFO(res.data); // 用户信息
            this.$message({
              type: 'success',
              message: '登录成功',
              duration: 1000
            });
            // this.isShowHeader = false
            // this.isShowHeader = true
          })
          .catch((req) => {
            console.log(req, 'token拿取失败');
          });
      }
    } else {
      console.log(localStorage.getItem('tmj_token'), 'token获取');
    }
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  async mounted() {
    window.onmessage = async (e) => {
      const isProduction = process.env.NODE_ENV === 'production';
      // let flag = isProduction ? config.mainEntry.origin.includes(e.origin) : true
      let flag = !!isProduction;
      // 本地调试
      if (e.origin.includes('localhost')) flag = true;
      if (flag && e.data) {
        if (e.data.constructor !== String) return;
        const data = JSON.parse(e.data);
        // const data = e.data
        e.source.postMessage('success', e.origin);
        // window.location.href = window.location.origin + window.location.pathname
        console.log('data==============>', data);
        window.localStorage.setItem('user_info', data.userInfo);
        window.localStorage.setItem('tmj_token', data.token);
        try {
          const pms = await LoginAPI.permission();
          if (pms.code === 200) {
            this.SET_PERMISSION(pms.data);
            this.SET_USERINFO(data.userInfo);
            window.location.href = window.location.origin + window.location.pathname;
            window.open(window.location.href, '_self');
          }
        } catch (e) {
          console.log('错误信息', e);
        }
      }
    };
    this.isShowHeader = true;
    // setTimeout(()=>{
    //       this.isShowHeader = true
    //       console.log('头部显示')
    // },1000)

    // await this.uav_init_ws();
    // this.get_son_token();
    this.$bus.$on('headerFn', () => {
      this.c_dashBoard.isShow = false;
      this.uav_de_location();
      this.uavInfo = {};
    });
    this.$bus.$on('clickUavTitle', () => {
      this.uav_flyToCenter();
    });
    this.$bus.$on('clickUavTab', () => {
      this.uav_isShow_entity();
    });
    this.$bus.$on('reloadHome', () => {
      this.reload();
    });
    this.$bus.$on('changeFlow', (isFlow) => {
      // console.log('changeFlow', this.taskDeviceData?.data?.videoInfo);
      // 切换到下一个视频格式
      let index = null;
      const types = this.taskDeviceData?.data?.videoInfo?.map((item) => {
        return item.videoType;
      });
      if (types) {
        // console.log('types', types);
        index = types.indexOf(this.videoType);
        if (index === this.taskDeviceData.data.videoInfo.length - 1) {
          index = 0;
        } else {
          index++;
        }
        this.videoType = types[index];
      }
    });
  },

  methods: {
    ...mapMutations([
      'SET_USERINFO',
      'SET_PERMISSION',
      'SET_UAVLOGIN_INFO',
      'SET_CESIUM_STATE',
      'SET_TASK_DEVICE_DATA'
    ]),
    ...methods,
    async wxLogin() {
      // let href = window.location.href
      // let data1 = href.split('?')[1].split('#')[0].split('&')
      // console.log(data1,'456456')
      // let data = {}
      // data1.forEach((item,index)=>{
      //   if(index == 0){
      //     data.code = item.split('=')[1]
      //   }
      //   if(index == 1){
      //     data.state = item.split('=')[1]
      //   }
      // })
      // console.log(data,'454132212')
      // // if (data.code && data.state) {
      //   // code=051JMO0w3CViWZ2fcm0w3P4wol2JMO0o&state=onlineEdu#/home
      //   await LoginAPI.wxToken(data).then((res) => {
      //     console.log(res,'进来了')
      //     window.localStorage.setItem(
      //       'user_info',
      //       JSON.stringify(res.data)
      //     )
      //     window.localStorage.setItem('tmj_token', res.data.token)
      //     this.SET_USERINFO(res.data) // 用户信息
      //     this.$message({
      //       type: 'success',
      //       message: '登录成功',
      //       duration: 1000,
      //     })
      //     this.$router.path('/home')
      //   })
      // // }
    },

    // 传送最新pointsInfo
    get_newest_pointsinfo(e) {
      this.newest_pointsinfo = e;
    },
    set_controlShow(e) {
      this.controlShow.isShow = e;
    },
    // 传送实体类
    send_data_srouce(e) {
      this.data_srouce_from_add = e;
    },
    // 传送位置
    send_positions(e) {
      this.positions_from_add = e;
    },

    AiChange(aiArr, index) {
      this.aiInfo = aiArr;
      // console.log('this.aiInfo:', this.aiInfo);
      // if (this.c_player.isShow) {
      this.c_player_ai.isShow = true;
      this.currentIndex = index;
      // }
    },
    // 拖拽后的航点
    edit_point_info(e) {
      this.points_info = e;
    },
    // 删除地图规划的航线
    destroy_cesium() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.waypointPopup.destroy_cesium_handler();
      });
    },
    // 删除编辑航线
    clear_airline() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.flightPopup.clear_airline_entitys();
      });
    },
    // 取消点击事件
    cancel_Handler() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.waypointPopup.cancel_Handler();
      });
    },

    // 航线规划
    save_airline() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.waypointPopup.save();
      });
    },
    // AI列表
    active_ailist() {
      this.is_active_ailist = !this.is_active_ailist;
    },
    isShowList() {},
    enter() {
      this.see = true;
    },
    leave() {
      this.see = false;
    },
    pointInfo(e) {
      this.point_info = e;
    },
    get_flight_info(e) {
      this.flight_info = e;
    },
    get_route_info(e) {
      this.route_info = e;
    },
    get_airline_info(e) {
      this.airline = e;
    },
    send_airline_info(e) {
      this.airline = e;
    },
    send_to_tooptip(e) {
      this.tooptip_flight_info = e;
    },
    get_flight_state(e) {
      this.isFromEdit = e;
    },
    get_sidelist_item(e) {
      this.side_list_item = e;
    },
    async get_son_token() {
      // ws获取token代码--lgq
      if (window.localStorage.getItem('tmj_token')) {
        const token = window.localStorage.getItem('tmj_token');

        const data = {
          loginInfo: {
            appId: '40004',
            token: token
          }
        };
        const control_login_info = await Control_API.login(data);
        if (control_login_info.data) {
          this.SET_UAVLOGIN_INFO({
            tmj_FLYINGSESSIONID: control_login_info.data['flyingSessionId'],
            'tmj_mmc-identity': control_login_info.data['token']
          });
          window.localStorage.setItem(
            'ws_flyingSessionId',
            control_login_info.data['flyingSessionId']
          );
          window.localStorage.setItem('ws_token', control_login_info.data['token']);
        }
      }
    },

    // change_active(e, index) {
    //   let tmj_token = window.localStorage.getItem("tmj_token");
    //   let dianli_token = window.localStorage.getItem("dianli_token");
    //   let shiyou_token = window.localStorage.getItem("shiyou_token");

    //   let space_token = window.localStorage.getItem("space_token");
    //   this.active = e;
    //   this.airline = e
    // },
    send_to_line_tooptip(e) {
      this.tooptip_line_info = e;
    },
    send_to_task_tooptip(e) {
      this.tooptip_flight_info = e;
    },
    // async change_active(e, index) {
    //   let tmj_token = window.localStorage.getItem("tmj_token");
    //   this.active = e;
    async change_active(active, index) {
      this.active = active;
      // switch (index) {
      //   case 0:
      //     this.$store.commit('CHANGE_ACTYIVE', 0)
      //     break
      //   case 1:
      //     this.$store.commit('CHANGE_ACTYIVE', 1)
      //     break
      //   case 2:
      //     this.$store.commit('CHANGE_ACTYIVE', 2)
      //     break
      // }
      if (index) {
        this.$store.commit('CHANGE_ACTYIVE', index);
      } else if (index === 0) {
        this.$store.commit('CHANGE_ACTYIVE', 0);
      }
    },
    // async getTrackData() {
    //   let data = await AirLine.details(this.flight_info.routeName);
    //   if (data.code == 200) {
    //     // this.trackPoints = data.points;
    //     this.flight_info.trackPoints = data.points;
    //   }
    // },
    fiflterPoint(uav_data) {
      this.flight_info.trackPoints.map((item) => ({
        latitude: item.latitude,
        longitude: item.longitude,
        isPass: !!(item.latitude === uav_data.latitude && item.longitude === uav_data.longitude)
      }));
    },
    // header的列表更改事件
    onHeaderChangeList(value) {
      this.active = 0;
      this.downoadPageShow = value === 'download';
    }
  },
  watch: {
    videos: {
      handler(value) {
        /* value.forEach((element) => {
          if (element.videoType == "flv" || element.videoType == "FLV") {
            // this.video =element ;
            // this.video = element
          }
          if (element.videoType == "rtmp" || element.videoType == "RTMP") {
            this.video_rtmp = element
          }
        }) */
      },
      deep: true,
      immediate: true
    },
    flight_info: {
      async handler(value) {
        if (Object.keys(value).length > 0 && value.deviceStatus) {
          if (!this.isFromEdit) {
            this.uav_init_ws();
            try {
              const res = await flightTaskAPI.deviceData({
                deviceHardId: value.deviceHardId
              });
              if (Array.isArray(res.data) && res.data.length > 0) {
                const deviceData = res.data.pop();
                this.uav.control.uavInfo = deviceData.data.uavInfo;
                this.uav_fn(1, this.uav.control.uavInfo);
                this.SET_CESIUM_STATE(true);
                this.SET_TASK_DEVICE_DATA(deviceData);
              }
            } catch (e) {}
          }
        } else {
          try {
            this.uav_destroy_ws();
            this.uav_de_location();
            this.uavInfo = {};
          } catch (err) {
            // console.log(err);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  destroyed() {
    this.uav_destroy_ws();
  }
};
</script>

<style lang="scss" scoped>
.views-home {
  height: 100%;
}
.ai_player {
  // position: relative;
  height: 100%;
}
</style>
