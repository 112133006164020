<template>
  <div class="run-project">
    <div v-interact class="run-project-box">
      <div class="run-project-head">
        {{$t('flightApplication.查看详情') }}
        <i class="el-icon-close" @click="onClose" />
      </div>
      <div class="run-project-body">
        <div class="body-title pd-box">{{$t('flightApplication.企业信息') }}:</div>
        <div class="body-item pd-box">
          <div class="item-box">
            <span class="item-label">{{$t('industryApplication.projectName')}}:</span>
            <el-input v-model="runProjectItem.name" readonly />
          </div>
          <div class="item-box">
            <span class="item-label">{{$t('flightApplication.联系人')}}:</span>
            <el-input v-model="runProjectItem.contacts" readonly />
          </div>
        </div>
        <div class="body-item pd-box">
          <div class="item-box">
            <span class="item-label">{{$t('flightApplication.应用类型')}}:</span>
            <el-input v-model="runProjectItem.type" readonly />
          </div>
          <div class="item-box">
            <span class="item-label">{{$t('flightApplication.联系人电话')}}:</span>
            <el-input v-model="runProjectItem.phone" readonly />
          </div>
        </div>
        <div class="body-item pd-box">
          <div class="item-box">
            <span class="item-label">{{$t('flightApplication.应用')}}:</span>
            <el-input v-model="runProjectItem.appName" readonly />
          </div>
          <div class="item-box">
            <span class="item-label">{{$t('flightApplication.联系人邮箱')}}:</span>
            <el-input v-model="runProjectItem.email" readonly />
          </div>
        </div>
        <div v-if="runProjectItem.type === '内部应用'" class="pd-box body-footer">
          <span class="item-label">{{$t('flightApplication.版本')}}: </span>
          <el-radio-group v-model="runProjectItem.versions" disabled>
            <el-radio v-for="item in versionsList" :key="item.value" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
        <div class="pd-box body-footer">
          <span class="item-label">{{$t('flightApplication.项目定位')}}: </span>
          <el-input v-model="runProjectItem.math" readonly />
        </div>
        <div class="pd-box body-footer">
          <span class="item-label">{{$t('flightApplication.备注描述')}}: </span>
          <el-input v-model="runProjectItem.remark" type="textarea" :rows="2" readonly />
        </div>
      </div>
      <div class="run-project-footer">
        <el-button type="primary" @click="onClose">{{ $t('operation.Close') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['change_active'],
  data() {
    return {
      runProjectItem: {},
      versionsList: [
        { value: 'STANDARD', label: this.$t('flightApplication.标准版')},
        { value: 'MAJOR', label: this.$t('flightApplication.专业版')},
        { value: 'SHIP', label: this.$t('flightApplication.旗舰版')}
      ]
    };
  },
  created() {},
  mounted() {
    this.$bus.$off('runProjectDetails');
    this.saveRunProjectItem();
  },
  methods: {
    onClose() {
      this.change_active(0);
    },
    typeFilter(type) {
      // 1  内部应用  2 外部应用  3 推广应用
      if (type == 1) {
        this.runProjectItem.type = this.$t('flightApplication.内部应用');
      } else if (type == 2) {
        this.runProjectItem.type = this.$t('flightApplication.外部应用');
      } else if (type == 3) {
        this.runProjectItem.type = this.$t('flightApplication.推广应用');
      }
    },
    saveRunProjectItem() {
      this.$bus.$on('runProjectDetails', (data) => {
        this.runProjectItem = data;
        this.typeFilter(this.runProjectItem.type);
        this.runProjectItem.math = `${this.$t('home.longitude')}${this.runProjectItem.lng}，${this.$t('home.latitude')}${this.runProjectItem.lat}`;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.run-project {
  /* background: rgba(19, 21, 24, 0.7); */
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;
  border: 2px solid #2e63dc;
  width: 900px !important;
  height: 540px !important;
  position: absolute;
  text-align: left;
  padding: 0;
  margin: 0;
  top: calc(50% - 260px);
  left: calc(50% - 400px);
  .run-project-box {
    .run-project-head {
      height: 40px;
      background-color: #2a2c33;
      color: #ccc;
      line-height: 40px;
      padding-left: 10px;
      margin-bottom: 10px;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid #ccc;
    }
    .run-project-body {
      height: 400px;
      color: #87888a;
      background-color: #000;
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
      overflow: auto;
      .pd-box {
        padding-bottom: 10px;
      }
      .body-title {
        color: #fff;
      }
      .body-item {
        display: flex;
        justify-content: space-between;
        .item-box {
          display: flex;
          flex: 1;
          &:first-child {
            margin-right: 20px;
          }
          .item-label {
            width: 150px;
          }
        }
      }
      .body-footer {
        display: flex;
        .item-label {
          width: 120px;
        }
      }
    }
    .run-project-footer {
      padding-top: 6px;
      text-align: center;
    }
  }
}
::v-deep {
  .el-textarea__inner {
    background-color: #16181b;
    color: #b3bbc5;
    border: none;
  }
}
</style>
